import {loadChecklistConfig, loadIndicatorConfig, resultMonitorChecklistSources} from "../../modules/result_monitoring/ResultMonitor";
import {getCznIndicatorsByPeriod, getRegionIndicatorsByPeriod} from "../../modules/api.indicators";

const domainChecklist = "result_monitor_checklist"
const domainIndicator = "result_monitor_indicator"

let departmentsByRegion
export function setDepartmentsByRegion(val){
    departmentsByRegion = JSON.parse(JSON.stringify(val))
}

let checklistConfig
export function setChecklistConfig(config) {
    checklistConfig = config
}

function parseValuesToFloat(values) {
    return values.map(item => {
        return {
            ...item,
            value: parseFloat(item.value)
        };
    });
}

function getSubjectReportDataFillPercentage(config, values, regionCode, sourceName) {
    let configParams = config.filter(e => e.source === sourceName).map(e => e.id)
    let valuesFiltered = values.filter(e => e.value != null &&
        e.value !== '' &&
        e.regionCode === regionCode &&
        configParams.includes(e.indicatorId))
    return valuesFiltered.length / configParams.length
}

/**
 * Считает и заполняет для регионов значения показателей
 */
async function computeRegionIndicators(values){
    const indicatorsConfig = await loadIndicatorConfig()
    for (let region of departmentsByRegion) {
        region.resultMonitorValues = {}
        for (let indicatorConfig of indicatorsConfig) {
            const value = values.find(e => e.indicatorId === indicatorConfig.id && e.regionCode === region.region.code)
            region.resultMonitorValues[indicatorConfig.type + indicatorConfig.code] = value ? parseFloat(value.value) : 0
        }
    }
}

/**
 * Считает и заполняет для ЦЗН значения показателей
 */
async function computeDepartmentIndicators(regionWithDepartments, values){
    const indicatorsConfig = await loadIndicatorConfig()

    for (let department of regionWithDepartments.departments) {
        department.resultMonitorValues = {}
        for (let indicatorConfig of indicatorsConfig) {
            const value = values.find(e => e.indicatorId === indicatorConfig.id && e.cznId === department.id)
            department.resultMonitorValues[indicatorConfig.type + indicatorConfig.code] = value ? parseFloat(value.value) : 0
        }
    }
}

function getValue(values, region, department, indicatorId){
    let v
    if (department && department.type === 'territorial_czn') {
        v = values.find(e => e.cznId && e.cznId === department.id && mappingWithOldConfig(e.indicatorId) === indicatorId)
    } else {
        const regionCode = region ? region : department.region.code
        v = values.find(e => e.regionCode === regionCode && e.cznId == null && mappingWithOldConfig(e.indicatorId) === indicatorId)
    }
    if (!v || !v.value) return 0
    return v.value
}

function mappingWithOldConfig(id) {
    return checklistConfig.find(e => e.id === id)?.code
}

/**
 * Отчет по субъектам Российской Федерации о заполнении сведений, необходимых для оценки результативности оказания государственных услуг по оценки эффективности и результативности деятельности
 */
export async function getSubjectReportData(period) {
    const config = await loadChecklistConfig()
    const values = parseValuesToFloat((await getCznIndicatorsByPeriod(domainChecklist, 0, period)).payload)

    let reportData = {
        headerCells: [
            {
                caption: "Субъект РФ",
                width: 5000,
            }
        ],
        rows: []
    }

    for (let source of resultMonitorChecklistSources) {
        reportData.headerCells.push({width: 5000, caption: 'Заполнение сведений раздела "' + source.title + '", %'})
    }
    for (let region of departmentsByRegion) {
        let row = [{
            cellDataType: "STRING", value: region.name
        }]
        for (let source of resultMonitorChecklistSources) {
            row.push({
                cellDataType: "PERCENT",
                value: getSubjectReportDataFillPercentage(config, values, region.code, source.name),
            })
        }
        reportData.rows.push(row)
    }

    return reportData
}

/**
 * Отчет по субъектам Российской Федерации по секции
 */
export async function getByServiceReportData(sectionName, period){
    const indicatorValues = parseValuesToFloat((await getCznIndicatorsByPeriod(domainIndicator, 0, period)).payload)
    const checklistValues = parseValuesToFloat((await getCznIndicatorsByPeriod(domainChecklist, 0, period)).payload)
    const values = [
        ...indicatorValues,
        ...checklistValues
    ]

    await computeRegionIndicators(values)

    let indicatorsConfig = (await loadIndicatorConfig())
    if (sectionName) {
        indicatorsConfig = indicatorsConfig.filter(e => e.section === sectionName)
    }
    let reportData = {
        headerCells: [
            {
                caption: "Субъект РФ",
                width: 5000,
            }
        ],
        rows: []
    }

    for (let question of checklistConfig ){
        reportData.headerCells.push({
            caption: question.code + ". " + question.name,
            width: 5000,
        })
    }

    for (let indicatorConfig of indicatorsConfig) {
        reportData.headerCells.push({
            caption: ((indicatorConfig.type === 'FILL' || indicatorConfig.type === 'R') ? '' : indicatorConfig.type + indicatorConfig.code + ". ") + indicatorConfig.name,
            width: 5000,
        })
    }

    for (let region of departmentsByRegion) {
        let row = [{
            cellDataType: "STRING", value: region.name
        }]

        for (let question of checklistConfig) {
            row.push({
                cellDataType: "DECIMAL",
                value: getValue(values, region.code, null, question.code),
            })
        }

        for (let indicatorConfig of indicatorsConfig) {
            row.push({
                cellDataType: getCellDataTypeFromUnit(indicatorConfig.unit),
                value: region.resultMonitorValues[indicatorConfig.type + indicatorConfig.code],
            })
        }
        reportData.rows.push(row)
    }

    return reportData
}

/**
 * Сводный отчет по ЦЗН субъекта Российской Федерации по показателям результативности оказания государственных услуг
 */
export async function getRegionalReport(period, regionAndDepartmentInfo){
    let regionWithDepartments = departmentsByRegion.find(e => e.code === regionAndDepartmentInfo.region.code)

    const indicatorValues = await getRegionIndicatorsByPeriod(domainIndicator, regionWithDepartments.code, period, 'ANY');
    const checklistValues = await getRegionIndicatorsByPeriod(domainChecklist, regionWithDepartments.code, period, 'ANY');

    const values = [
        ...parseValuesToFloat(indicatorValues.payload),
        ...parseValuesToFloat(checklistValues.payload),
    ];

    await computeRegionIndicators(values)
    await computeDepartmentIndicators(regionWithDepartments, values)
    const checklistConfig = await loadChecklistConfig()
    const indicatorsConfig = await loadIndicatorConfig()

    let reportData = {
        headerCells: [
            {
                caption: "ЦЗН",
                width: 10000,
            }
        ],
        rows: []
    }

    for (let question of checklistConfig) {
        reportData.headerCells.push({
            caption: question.code + ". " + question.name,
            width: 5000,
        })
    }
    for (let indicator of indicatorsConfig) {
        reportData.headerCells.push({
            caption: ((indicator.type === 'FILL' || indicator.type === 'R') ? '' : indicator.type + indicator.code + ". ")  + indicator.name,
            width: 5000,
        })
    }

    for (let department of regionWithDepartments.departments) {
        let row = [{
            cellDataType: "STRING", value: department.name
        }]
        for (let question of checklistConfig) {
            row.push({
                cellDataType: "DECIMAL",
                value: getValue(values, null, department, question.code),
            })
        }
        for (let indicator of indicatorsConfig) {
            row.push({
                cellDataType: getCellDataTypeFromUnit(indicator.unit),
                value: department.resultMonitorValues[indicator.type + indicator.code],
            })
        }
        reportData.rows.push(row)
    }

    return reportData
}

/**
 * Отчет по отдельному ЦЗН по показателям результативности оказания государственных услуг
 */
export async function getCznReport(period, regionAndDepartmentInfo){
    let regionWithDepartments = departmentsByRegion.find(e => e.code === regionAndDepartmentInfo.region.code)
    let department = regionWithDepartments.departments.find(e => e.id === regionAndDepartmentInfo.department.id)

    const indicatorValues = await getRegionIndicatorsByPeriod(domainIndicator, regionWithDepartments.code, period, 'ANY');
    const checklistValues = await getRegionIndicatorsByPeriod(domainChecklist, regionWithDepartments.code, period, 'ANY');

    const values = [
        ...parseValuesToFloat(indicatorValues.payload),
        ...parseValuesToFloat(checklistValues.payload)
    ];

    await computeRegionIndicators(values)
    await computeDepartmentIndicators(regionWithDepartments, values)
    const checklistConfig = await loadChecklistConfig()
    const indicatorsConfig = await loadIndicatorConfig()
    let reportData = {
        headerCells: [
            {
                caption: "ЦЗН",
                width: 10000,
            }
        ],
        rows: []
    }

    for (let question of checklistConfig ){
        reportData.headerCells.push({
            caption: question.code + ". " + question.name,
            width: 5000,
        })
    }
    for (let indicator of indicatorsConfig) {
        reportData.headerCells.push({
            caption: ((indicator.type === 'FILL' || indicator.type === 'R') ? '' : indicator.type + indicator.code + ". ")   + indicator.name,
            width: 5000,
        })
    }

    let row = [{
        cellDataType: "STRING", value: department.name
    }]
    for (let question of checklistConfig) {
        row.push({
            cellDataType: "DECIMAL",
            value: getValue(values, null, department, question.code),
        })
    }
    for (let indicator of indicatorsConfig) {
        row.push({
            cellDataType: getCellDataTypeFromUnit(indicator.unit),
            value: department.resultMonitorValues[indicator.type+indicator.code],
        })
    }
    reportData.rows.push(row)
    return reportData
}

function normalizeRating(rating){
    rating = rating.map(e => [e[0], e[1] * 100, e[4], e[2], e[3], e[4]]);
    rating.sort((a,b) => a[1] <= b[1] ? 1 : -1);
    return rating;
}

export async function getRatingByIndicator(code, baseIndicatorCode, level, period, regionAndDepartmentInfo){
    let baseObjects = await getRegionsWithComputedIndicators(level, period, regionAndDepartmentInfo);
    console.log(baseObjects, 'baseObjects')
    const config = await loadIndicatorConfig()

    const indicator = config.find(e => e.code === code)
    const baseIndicator = baseIndicatorCode && config.find(e => e.code === baseIndicatorCode)
    return normalizeRating(baseObjects.map(e => [
        e.name,
        e.resultMonitorValues[indicator.type + indicator.code],
        baseIndicator ? e.resultMonitorValues[baseIndicator.type + baseIndicator.code] : null,
        baseIndicator ? baseIndicator.unit : null,
        e.region.code
    ]));
}

async function getRegionsWithComputedIndicators(level, period, regionAndDepartmentInfo){
    if (level === 'region') {
        const values = parseValuesToFloat((await getCznIndicatorsByPeriod(domainIndicator, 0, period)).payload)
        await computeRegionIndicators(values)
        return departmentsByRegion
    } else if (level === 'czn') {
        const regionWithDepartments = departmentsByRegion.find(e => e.code === regionAndDepartmentInfo.region.code)
        const values = parseValuesToFloat((await getRegionIndicatorsByPeriod(domainIndicator, regionWithDepartments.code, period, 'FILLED')).payload)
        await computeDepartmentIndicators(regionWithDepartments, values)
        return regionWithDepartments.departments.filter(e => e.type === 'territorial_czn')
    }
}

function getCellDataTypeFromUnit(unit){
    switch (unit){
        case 'чел.': case 'ед.': case 'мес.': case 'тыс. чел.': case 'дней':
            return 'DECIMAL'
        case '%':
            return 'PERCENT'
        default:
            return 'DECIMAL'
    }
}

export const resultMonitorRatings = [
    {
        id: 1,
        title: 'Рейтинг по показателю П2 «Доля трудоустроенных на постоянную работу в численности граждан, обратившихся в целях поиска подходящей работы»',
        group: 'Содействие гражданам в поиске подходящей работы',
        dataFunction: getRatingByIndicator.bind(null, '46', '2')
    },
    {
        id: 2,
        title: 'Рейтинг по показателю П2а «Доля трудоустроенных в численности граждан, обратившихся в целях поиска подходящей работы»',
        group: 'Содействие гражданам в поиске подходящей работы',
        dataFunction: getRatingByIndicator.bind(null, '47', '2а')
    },
    {
        id: 3,
        title: 'Рейтинг по показателю О3 «Динамика средней продолжительности безработицы по отношению к аналогичному периоду предшествующего календарного года»',
        group: 'Содействие гражданам в поиске подходящей работы',
        dataFunction: getRatingByIndicator.bind(null, '48', '3')
    },
    {
        id: 4,
        title: 'Рейтинг по показателю П5 «Отношение численности трудоустроенных граждан к количеству свободных рабочих мест и вакантных должностей, исключенных из базы данных ЦЗН за отчетный период»',
        group: 'Содействие работодателям в подборе необходимых работников',
        dataFunction: getRatingByIndicator.bind(null, '49', '5')
    },
    {
        id: 5,
        title: 'Рейтинг по показателю П7 «Доля граждан, нашедших доходное занятие (в том числе трудоустроенных) после завершения профессионального обучения или получения дополнительного профессионального образования из числа безработных граждан, завершивших профессиональное обучение или получивших ДПО в отчетном периоде или периоде, предшествующем отчетному»',
        group: 'Организация профессионального обучения и дополнительного профессионального образования',
        dataFunction: getRatingByIndicator.bind(null, '50', '7')
    },
    {
        id: 6,
        title: 'Рейтинг по показателю П10 «Доля безработных граждан, нашедших доходное занятие после получения государственной услуги "Организация профессиональной ориентации граждан", в численности безработных граждан, получивших указанную услугу»',
        group: 'Организация профессиональной ориентации граждан',
        dataFunction: getRatingByIndicator.bind(null, '51', '10')
    },
    {
        id: 7,
        title: 'Рейтинг по показателю П10а «Отношение количества оказанных услуг "Организация профессиональной ориентации граждан" гражданам старше 17 лет к количеству заявлений от граждан, обратившихся в целях поиска подходящей работы в течение отчетного периода»',
        group: 'Организация профессиональной ориентации граждан',
        dataFunction: getRatingByIndicator.bind(null, '52', '10а')
    },
    {
        id: 8,
        title: 'Рейтинг по показателю П12 «Отношение численности граждан, оформивших государственную регистрацию в качестве юридического лица или индивидуального предпринимателя, вставших на учет в качестве самозанятого в течение отчетного периода, к численности граждан, признанных безработными в отчетном периоде»',
        group: 'Содействие началу осуществления предпринимательской деятельности',
        dataFunction: getRatingByIndicator.bind(null, '53', '12')
    },
    {
        id: 9,
        title: 'Рейтинг по показателю П14 «Доля трудоустроенных инвалидов в общей численности инвалидов, получивших государственную услугу "Организация сопровождения при содействии занятости инвалидов"»',
        group: 'Содействие занятости инвалидов',
        dataFunction: getRatingByIndicator.bind(null, '54', '14')
    },
    {
        id: 10,
        title: 'Рейтинг по показателю П15 «Доля трудоустроенных инвалидов из числа инвалидов, обратившихся в органы службы занятости за содействием в поиске подходящей работы»',
        group: 'Содействие занятости инвалидов',
        dataFunction: getRatingByIndicator.bind(null, '55', '15')
    },
    {
        id: 11,
        title: 'Рейтинг по показателю П17 «Доля граждан, нашедших доходное занятие после получения государственной услуги "Психологическая поддержка безработных граждан" в численности безработных граждан, получивших указанную услугу»',
        group: 'Психологическая поддержка безработных граждан',
        dataFunction: getRatingByIndicator.bind(null, '56', '17')
    },
    {
        id: 12,
        title: 'Рейтинг по показателю П19 «Доля граждан, нашедших доходное занятие после получения государственной услуги "Социальная адаптация безработных граждан на рынке труда" в численности безработных граждан, получивших указанную услугу»',
        group: 'Социальная адаптация безработных граждан',
        dataFunction: getRatingByIndicator.bind(null, '57', '19')
    },
    {
        id: 13,
        title: 'Рейтинг по показателю П23 «Доля несовершеннолетних граждан в возрасте от 14 до 18 лет, трудоустроенных на временные работы в свободное от учебы время, из числа граждан данной категории, обратившихся за предоставлением государственной услуги "Организация временного трудоустройства" или согласились с предложением о ее предоставлении»',
        group: 'Организация временного трудоустройства',
        dataFunction: getRatingByIndicator.bind(null, '58', '23')
    },
    {
        id: 14,
        title: 'Рейтинг по показателю П24 «Доля безработных граждан, испытывающих трудности в поиске работы, трудоустроенных на временные работы, из числа безработных граждан, обратившихся за предоставлением государственной услуги "Организация временного трудоустройства" или согласились с предложением о ее предоставлении»',
        group: 'Организация временного трудоустройства',
        dataFunction: getRatingByIndicator.bind(null, '59', '24')
    },
    {
        id: 15,
        title: 'Рейтинг по показателю П25 «Доля безработных граждан в возрасте от 18 до 25 лет, имеющих высшее или среднее профессиональное образование, ищущих работу в течение года с даты выдачи им документа об образовании и о квалификации, трудоустроенных на временные работы, из числа граждан данной категории, обратившихся за предоставлением государственной услуги "Организация временного трудоустройства" или согласились с предложением о ее предоставлении»',
        group: 'Организация временного трудоустройства',
        dataFunction: getRatingByIndicator.bind(null, '60', '25')
    },
    {
        id: 16,
        title: 'Рейтинг по показателю П27 «Доля граждан, зарегистрированных в целях поиска подходящей работы, в том числе безработных граждан, осуществивших переезд или переселение в результате получения государственной услуги "Содействие безработным гражданам и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, в переезде и безработным гражданам и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, и членам их семей в переселении в другую местность для трудоустройства по направлению органов службы занятости", в численности граждан, обратившихся за указанной услугой или согласившихся с предложением о ее предоставлении»',
        group: 'Содействие в переезде и переселении',
        dataFunction: getRatingByIndicator.bind(null,  '61', '27')
    },
    {
        id: 17,
        title: 'Сводный рейтинг результативности оказания государственной услуги "Содействие гражданам в поиске подходящей работы"',
        group: 'Сводные и интегральный рейтинги результативности оказания госуслуг',
        dataFunction: getRatingByIndicator.bind(null,  '35', null)
    },
    {
        id: 18,
        title: 'Сводный рейтинг результативности оказания государственной услуги "Содействие работодателям в подборе необходимых работников"',
        group: 'Сводные и интегральный рейтинги результативности оказания госуслуг',
        dataFunction: getRatingByIndicator.bind(null,  '62', null)
    },
    {
        id: 19,
        title: 'Сводный рейтинг результативности оказания государственной услуги "Организация профессионального обучения и дополнительного профессионального образования безработных граждан, включая обучение в другой местности"',
        group: 'Сводные и интегральный рейтинги результативности оказания госуслуг',
        dataFunction: getRatingByIndicator.bind(null,  '63', null)
    },
    {
        id: 20,
        title: 'Сводный рейтинг результативности оказания государственной услуги "Организация профессиональной ориентации граждан"',
        group: 'Сводные и интегральный рейтинги результативности оказания госуслуг',
        dataFunction: getRatingByIndicator.bind(null,  '36', null)
    },
    {
        id: 21,
        title: 'Сводный рейтинг результативности оказания государственной услуги "Содействие началу осуществления предпринимательской деятельности"',
        group: 'Сводные и интегральный рейтинги результативности оказания госуслуг',
        dataFunction: getRatingByIndicator.bind(null,  '64', null)
    },
    {
        id: 22,
        title: 'Сводный рейтинг результативности оказания государственной услуги "Организация сопровождения при содействии занятости инвалидов" и полномочий по содействию занятости инвалидов',
        group: 'Сводные и интегральный рейтинги результативности оказания госуслуг',
        dataFunction: getRatingByIndicator.bind(null,  '37', null)
    },
    {
        id: 23,
        title: 'Сводный рейтинг результативности оказания государственной услуги "Психологическая поддержка безработных граждан"',
        group: 'Сводные и интегральный рейтинги результативности оказания госуслуг',
        dataFunction: getRatingByIndicator.bind(null,  '65', null)
    },
    {
        id: 24,
        title: 'Сводный рейтинг результативности оказания государственной услуги "Социальная адаптация безработных граждан на рынке труда"',
        group: 'Сводные и интегральный рейтинги результативности оказания госуслуг',
        dataFunction: getRatingByIndicator.bind(null,  '66', null)
    },
    {
        id: 25,
        title: 'Сводный рейтинг результативности оказания государственной услуги "Организация временного трудоустройства"',
        group: 'Сводные и интегральный рейтинги результативности оказания госуслуг',
        dataFunction: getRatingByIndicator.bind(null,  '38', null)
    },
    {
        id: 26,
        title: 'Сводный рейтинг результативности оказания государственной услуги "Содействие безработным гражданам и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, в переезде и безработным гражданам и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, и членам их семей в переселении в другую местность для трудоустройства по направлению органов службы занятости"',
        group: 'Сводные и интегральный рейтинги результативности оказания госуслуг',
        dataFunction: getRatingByIndicator.bind(null,  '67', null)
    },
    {
        id: 27,
        title: 'Интегральный рейтинг результативности оказания государственных услуг',
        group: 'Сводные и интегральный рейтинги результативности оказания госуслуг',
        dataFunction: getRatingByIndicator.bind(null, '34', null)
    },
]

export const effectivenessRatings = [
    {
        id: 1,
        title: 'Рейтинг по показателю П28 «Отношение численности граждан, обратившихся в ЦЗН в целях поиска подходящей работы, к численности безработных по данным выборочного наблюдения»',
        dataFunction: getRatingByIndicator.bind(null, '68', '28')
    },
    {
        id: 2,
        title: 'Рейтинг по показателю П29 «Доля работодателей-организаций, обратившихся за содействием в подборе необходимых работников в ЦЗН, в общем количестве организаций»',
        dataFunction: getRatingByIndicator.bind(null, '69', '29')
    },
    {
        id: 3,
        title: 'Рейтинг по показателю П30 «Доля граждан, нашедших доходное занятие (т.е. граждан, трудоустроенных на постоянную работу, и граждан, оформивших государственную регистрацию в качестве юридического лица или индивидуального предпринимателя, вставших на учет в качестве самозанятого), в численности граждан, обратившихся в ЦЗН в целях поиска подходящей работы»',
        dataFunction: getRatingByIndicator.bind(null, '70', '30')
    },
    {
        id: 4,
        title: 'Рейтинг по показателю О31д «Средняя продолжительность безработицы в днях»',
        dataFunction: getRatingByIndicator.bind(null, '71', '31д')
    },
    {
        id: 5,
        title: 'Рейтинг по показателю О32д «Средняя продолжительность поиска постоянной работы в днях»',
        dataFunction: getRatingByIndicator.bind(null, '72', '32д')
    },
    {
        id: 6,
        title: 'Рейтинг по показателю П33 «Численность граждан, нашедших при содействии ЦЗН доходное занятие (граждан, трудоустроенных на постоянную работу, и граждан, оформивших государственную регистрацию в качестве юридического лица или индивидуального предпринимателя, вставших на учет в качестве самозанятого), в расчете на одного сотрудника ЦЗН»',
        dataFunction: getRatingByIndicator.bind(null, '73', '33')
    },
    {
        id: 7,
        title: 'Совокупный рейтинг эффективности деятельности',
        dataFunction: getRatingByIndicator.bind(null, '39', null)
    },
]
