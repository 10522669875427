<template>
  <v-app id="app">
    <header>
      <div>
        <div class="wrap">
          <a href="https://new.czn-office.ru" target="_blank" class="logo"></a>


          <v-menu bottom left offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="round-btn" v-bind="attrs" v-on="on">
                <svg
                  width="18"
                  height="12"
                  viewBox="0 0 18 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 12H18V10H0V12ZM0 7H18V5H0V7ZM0 0V2H18V0H0Z"
                    fill="#0033A0"
                  />
                </svg>
              </v-btn>
            </template>
            <v-list>
              <v-list-item style="cursor: pointer" @click="openCznOffice()"
                ><router-link to="/">
                  <v-list-item-title>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16 0H2C0.89 0 0 0.89 0 2V6H2V2H16V16H2V12H0V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 0.89 17.1 0 16 0ZM7.08 12.58L8.5 14L13.5 9L8.5 4L7.08 5.41L9.67 8H0V10H9.67L7.08 12.58Z"
                        fill="#0033A0"
                      />
                    </svg>

                    Вход для сотрудников ЦЗН и ВНИИ Труда</v-list-item-title
                  >
                </router-link>
              </v-list-item>
              <v-list-item @click="openVirtz()">
                <v-list-item-title
                  ><router-link to="/">
                    <svg
                      width="22"
                      height="20"
                      viewBox="0 0 22 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11 0L15 4H12V11.85L18.53 15.61L20 13.03L21.5 18.5L16 19.96L17.53 17.35L11 13.58L4.47 17.35L6 19.96L0.5 18.5L2 13.03L3.47 15.61L10 11.85V4H7L11 0ZM20 3H18V1H20V3ZM21 8V10H17V8H18V6H17V4H20V8H21Z"
                        fill="#0033A0"
                      />
                    </svg>

                    Виртуальный информационно-ресурсный центр
                  </router-link></v-list-item-title
                >
              </v-list-item>
              <v-list-item @click="openBz()">
                <v-list-item-title
                  ><router-link to="/">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 7H11V5H9V7ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM9 15H11V9H9V15Z"
                        fill="#0033A0"
                      />
                    </svg>
                    База знаний ФЦКСЗ
                  </router-link></v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </header>

    <main>
      <router-view />
    </main>
  </v-app>
</template>

<style lang="scss">
@import "./styles/main.scss";
</style>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo {
    width: 277px;
    height: 70px;
    background: url('./assets/logo.png') no-repeat center/100%;
}
</style>



<script>
import { setDepartmentsByRegion, setPeriod } from './modules/OrgMonitor'
import {loadChecklistConfig } from "./modules/result_monitoring/ResultMonitor";
import { setDepartmentsByRegion as setDepartmentsByRegionForReports, setChecklistConfig } from './modules/result_monitoring/ResultMonitorReports'
import api from "./modules/api";
export default {
  props: {},
  data() {
    return {
      departmentsList: null
    };
  },
  methods: {
    openCznOffice(){
      location.href = "https://new.czn-office.ru"
    },

    openBz(){
      location.href = "https://czn-office.ru"
    },

    openVirtz(){
      location.href = "https://cznnew.ru"
    }
  },
  async beforeMount() {
    this.departmentsList = (await api.get("/department/getList")).payload;
    setDepartmentsByRegion(this.departmentsList)
    setPeriod(6)
    setDepartmentsByRegionForReports(this.departmentsList)
    setChecklistConfig(await loadChecklistConfig())
    // Vue.prototype.$departmentsList = this.departmentsList
  }
};
</script>
